<template>
  <div class="failure">
    <GlobalInfoBar
      title="指定机型自动报价"
      content="添加指定机型自动报价规则，设置指定报价机型"
    />
    <div class="class_type" v-if="false">
      <el-radio-group v-model="calssSelect">
        <el-radio-button label="01">指定机型自动报价设置</el-radio-button>
        <el-radio-button label="02">通用机型自动报价设置</el-radio-button>
      </el-radio-group>
    </div>
    <div class="button_top">
      <div class="faultBtn">
        <div
          :class="clickShow === 'zhuanzhuan' ? 'active' : 'none'"
          @click="BtnClick('zhuanzhuan')"
        >
          小米
        </div>
        <div
          :class="clickShow === 'not' ? 'active' : 'none'"
          @click="BtnClick('not')"
        >
          非小米
        </div>
        <div
          :class="clickShow === 'haihuishou' ? 'active' : 'none'"
          @click="BtnClick('haihuishou')"
        >
          华为
        </div>
        <div
          :class="clickShow === 'honor' ? 'active' : 'none'"
          @click="BtnClick('honor')"
        >
          闪回
        </div>
      </div>
    </div>
    <div class="case" v-loading="topreshloading">
      <div style="padding: 0 20px">
        <div class="tab_box">
          <el-tabs
            v-if="personalConfigList.length > 0"
            v-model="groupId"
            editable
            @tab-click="groupChange"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              v-for="item in personalConfigList"
              :key="item.id"
              :label="item.name"
              :name="item.id + ''"
            />
          </el-tabs>
          <el-link
            type="primary"
            :underline="false"
            class="add-btn"
            @click="addSelfConfig"
            >+添加规则</el-link
          >
        </div>
      </div>
      <div
        v-if="personalConfigList.length > 0"
        v-loading="reshloading"
        style="padding: 0 20px"
      >
        <div class="add_top_role">
          <div class="title-base">
            <span style="margin-right: 30px">基础规则</span>
            <el-button
              @click="updateRole"
              type="text"
              style="text-decoration: underline; margin-right: 10px"
              >修改></el-button
            >
            <el-button
            v-if="basicsConfigdata.state !== '02'"
              @click="biddenRole"
              type="text"
              style="text-decoration: underline; color: red"
              >禁用></el-button
            >
          </div>
          <div class="action_center">
            规则说明：{{ basicsConfigdata.ruleExplain || "--" }}
          </div>
          <div class="action_center">
            <div style="display: flex; align-items: center">
              生效状态：
              <div :class="{'status_st':basicsConfigdata.state =='01','status_grd':basicsConfigdata.state !== '01'}">
                {{
                  basicsConfigdata.state == "00"
                    ? "未生效"
                    : basicsConfigdata.state == "01"
                    ? "生效中"
                    : "已失效"
                }}
              </div>
            </div>
            <div>
              生效时间：<span style="color: #000">{{
                basicsConfigdata.effectiveTimeRegion
              }}</span>
            </div>
            <div>
              设置报价方式：<span style="color: #e6a23c">{{
                basicsConfigdata.quoteWay == "00" ? "自定义系数" : "自定义报价"
              }}</span>
            </div>
          </div>
          <div class="action_center" v-if="false">
            <span style="margin-right: 30px"
              >已设置<span class="blue_text" @click="seaRmRcant"
                >{{ newMerchantList.length }}个</span
              >商户</span
            >
            <span style="color: #e6a23c; cursor: pointer" @click="addMerchant"
              >点击添加></span
            >
          </div>
        </div>
        <div class="addspesial_phone">
          <div class="title-base">指定机型设置</div>
          <div class="action_center">
            <el-button size="small" type="primary" @click="saveSHSclick"
              >+添加机型</el-button
            >
            <span style="margin-left: 30px"
              >已添加<span class="blue_text"
                >{{ sampleImageSpecialData.length }}个</span
              >报价机型</span
            >
          </div>
        </div>
        <GlobalTable
          :maxHeight="800"
          class="sample-image"
          ref="GlobalTable"
          v-loading="tableLoading2"
          :columns="tableColumns2"
          :data="sampleImageSpecialData"
          :isPagination="false"
        >
          <el-table-column label="报价方式" slot="sampleImage" align="center">
            <template slot-scope="{ row }">
              {{ row.quoteWay == "00" ? "自定义系数" : "自定义报价" }}
            </template>
          </el-table-column>
          <el-table-column label="容量" slot="isMust" align="center">
            <template slot-scope="{ row }">
              {{ row.skuType == "all" ? "全部容量" : row.machineCapacity }}
            </template>
          </el-table-column>
          <el-table-column label="添加人" slot="plople" align="center">
            <template slot-scope="{ row }">
              {{ row.createName }}-{{ row.createAcc }}
            </template>
          </el-table-column>
          <el-table-column label="状态" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <el-switch v-model="row.isEnable" @change="switchChange(row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            slot="operation"
            width="280px"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="Modifyspecification(row)"
                >修改规格</el-button
              >
              <el-button size="mini" type="warning" @click="Setquotation(row)"
                >设置报价</el-button
              >
              <el-button size="mini" type="danger" @click="phoneDelate(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <!-- 商户列表 -->
    <GlobalDialog
      :show="merchantListShow"
      title="添加商户"
      @submitPopupData="merchantSave"
      :width="1000"
      top="6%"
      @onClose="merchantListShow = false"
      @resetPopupData="merchantListShow = false"
    >
      <el-form :model="merchantParam" inline size="mini" ref="searchForm">
        <el-form-item label="商户名称">
          <el-select
            v-model="merchantParam.companyId"
            filterable
            clearable
            placeholder="请输入商户名称查询"
          >
            <el-option
              v-for="item in storeSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            placeholder="请输入联系人姓名"
            v-model="merchantParam.contact"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            placeholder="请输入联系电话"
            v-model="merchantParam.phone"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 30px">
          <el-button type="primary" @click="searchMerchantClick"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        v-if="merchantListShow"
        ref="addMerchantDia"
        :data="merchantTableData"
        v-loading="merchantTableLoading"
        tooltip-effect="dark"
        border
        height="500px"
        @selection-change="handSelectMerchant"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" />
        <el-table-column
          v-for="item of bargainMerchantColumnsDia"
          :prop="item.prop"
          :key="item.prop"
          align="center"
          :label="item.label"
          :width="item.width"
        >
          <template v-slot="{ row }">
            <p>{{ row[item.prop] || "--" }}</p>
          </template>
        </el-table-column>
      </el-table>
    </GlobalDialog>
    <!-- 已设置商户 -->
    <el-dialog
      title="已设置商户"
      :visible.sync="redyMerchantvisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <div style="margin-bottom: 30px">
        <el-table
          v-if="redyMerchantvisible"
          ref="addMerchantDia"
          :data="newMerchantList"
          v-loading="merchantTableLoading"
          tooltip-effect="dark"
          border
          max-height="500px"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          />
          <el-table-column
            v-for="item of bargainMerchantColumnsDia"
            :prop="item.prop"
            :key="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
          >
            <template v-slot="{ row }">
              <p>{{ row[item.prop] || "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template v-slot="{ row }">
              <el-button type="danger" @click="delline(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 删除商户 -->
    <el-dialog
      title="操作提示"
      :visible.sync="detalevisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：删除后，商户不再参与该规则的指定机型自动报价
      </p>
      <div style="text-align: center; margin-bottom: 20px">
        是否确认删除<span style="color: #0981ff">{{
          selectData.companyName
        }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="ahsvisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="Shsconfrim"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除规则配置 -->
    <el-dialog
      title="删除提示"
      :visible.sync="detaleShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="detaleDialogClosed"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：删除后该配置下方的门店商户的指定机型将不再触发自定义报价
      </p>
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认删除<span style="color: #0981ff">{{
            detalegroupdata.name
          }}</span
          >的全部设置数据？
        </div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="detaleShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="detaleDialogSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 禁用规则配置 -->
    <el-dialog
      title="禁用提示"
      :visible.sync="forbiddenShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="detaleDialogClosed"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：禁用后，指定机型自动报价将不再按照该规则执行
      </p>
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认禁用<span style="color: #0981ff">{{
            detalegroupdata.ruleName
          }}</span
          >的自动报价规则？
        </div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="forbiddenShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="forbidSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加规则 -->
    <el-dialog
      :title="uptype == 'add' ? '添加规则' : '修改规则'"
      :visible.sync="insertionvisible"
      :close-on-click-modal="false"
      width="750px"
      @close="closeRued"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="规则名称" prop="ruleName">
          <el-input
           maxlength="20"
            v-model="ruleForm.ruleName"
            placeholder="请输入规则名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="生效日期"
          style="margin: 0 0 10px 0"
          prop="date"
          required
        >
          <el-date-picker
            v-model="ruleForm.date"
            :picker-options="pickerOptions"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" style="margin: 0" required>
          <div class="self_allra">
            <el-checkbox v-model="ruleForm.isImmediate">立即生效</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="设置报价方式" prop="quoteWay">
          <el-radio-group v-model="ruleForm.quoteWay">
            <el-radio label="01">自定义报价金额</el-radio>
            <el-radio label="00">自定义报价系数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="规则说明" prop="ruleExplain">
          <el-input
            type="textarea"
            maxlength="500"
            placeholder="请输入备注内容，最多500字"
            v-model="ruleForm.ruleExplain"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeRued">取消</el-button>
        <el-button type="primary" @click.native="rolSureconfirm('ruleForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改报价提示弹窗 -->
    <el-dialog
      title="操作提示"
      :visible.sync="modificationvisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：修改方式后，将默认清除该规则下机型的报价数据并禁用该机型，需在完成设置后重新启用
      </p>
      <div style="text-align: center; margin-bottom: 20px">
        是否确认修改报价设置？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="modificationvisible = false"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="modificationconfrim"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 启用禁用操作 -->
    <el-dialog
      title="操作提示"
      :visible.sync="isenActionShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="isenActionClosed"
    >
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认{{ skuCopyData.isEnable ? "启用" : "禁用" }}该机型的设置？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isenActionClosed">取消</el-button>
        <el-button type="primary" @click="isenActionSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 删除机型 -->
    <el-dialog
      title="删除提示"
      :visible.sync="dePhoneShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="detaleDialogClosed"
    >
      <p style="color: red; margin-bottom: 10px">
        说明：删除后该机型不再作为指定机型报价，仍可按通用机型规则触发自动报价
      </p>
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认删除<span style="color: #0981ff">{{
            basicsConfigdata.ruleName
          }}</span
          >的设置数据？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dePhoneShow = false">取消</el-button>
        <el-button type="primary" @click="dePhnoeSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加机型 -->
    <el-dialog
      :title="skumodeltype == 'add' ? '添加机型' : '修改规格'"
      :visible.sync="ahsvisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="offaddPhoneclose"
    >
      <el-form
        :model="editPriceForm"
        :rules="editPricerules"
        ref="editPriceForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧机品牌" prop="brandId">
          <el-select
            v-model="editPriceForm.brandId"
            clearable
            filterable
            :disabled="skumodeltype !== 'add'"
            @change="getModelList"
            placeholder="请选择品牌"
          >
            <el-option
              v-for="item in brandList"
              :key="item.brandNo"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="旧机机型" prop="modelId">
          <el-select
            clearable
            filterable
            @change="getModelidchange"
            :disabled="!editPriceForm.brandId || skumodeltype !== 'add'"
            v-model="editPriceForm.modelId"
            :placeholder="editPriceForm.brandId?'请选择旧机机型':'请先选择品牌'"
          >
            <el-option
              v-for="item in modelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="旧机容量" prop="skuType">
          <el-radio-group v-model="editPriceForm.skuType" @input="oldPhonese">
            <el-radio label="all">全部容量</el-radio>
            <el-radio label="target">自定义容量</el-radio>
          </el-radio-group>
        </el-form-item>
        <div
          style="margin: -20px 0 0 100px"
          v-loading="skuresh"
          v-if="editPriceForm.skuType == 'target'"
        >
          <el-tag
            v-for="(tag, index) in tags"
            :key="tag.name"
            @close="capacityClose(index)"
            closable
            style="margin: 0 10px 10px 0"
          >
            {{ tag.name }}
          </el-tag>
          <el-button
            type="primary"
            size="mini"
            v-if="skumodeltype == 'add'"
            style="margin-right: 10px"
            @click="oldPhonese"
            >重新获取</el-button
          >
          <el-button
            style="margin: 0"
            type="warning"
            icon="el-icon-plus"
            size="mini"
            @click="addCapacity"
            >添加容量</el-button
          >
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetSHSForm('editPriceForm')"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="phoneModelSave('editPriceForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加容量 -->
    <el-dialog
      title="添加容量"
      :visible.sync="usedDialogVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="handleDialogClosed"
    >
      <el-form
        :model="capacityForm"
        ref="rongForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="选择容量"
          prop="capacityan"
          v-if="iosleftoptions.length == 0"
        >
          <el-select
            v-model="capacityForm.capacityan"
            @change="anzhCapacity"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in anhuoptions"
              :key="item.id"
              :label="item.specName"
              :value="item.specName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择容量"
          prop="capacityIosl"
          v-if="iosleftoptions.length > 0"
        >
          <div style="display: flex">
            <el-col :span="11">
              <el-select
                clearable
                @change="iosLeftCapacity"
                v-model="capacityForm.capacityIosl"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in iosleftoptions"
                  :key="item.id"
                  :label="item.specName"
                  :value="item.specName"
                >
                </el-option>
              </el-select>
            </el-col>
            <span style="margin: 0 5px">+</span>
            <el-col :span="11">
              <el-select
                clearable
                multiple
                :disabled="!capacityForm.capacityIosl"
                @change="iosRightCapacity"
                v-model="capacityForm.capacityIosr"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in iosrightoptions"
                  :key="item.id"
                  :label="item.specName"
                  :value="item.specName"
                >
                </el-option>
              </el-select>
            </el-col>
          </div>
        </el-form-item>
        <div style="margin: -10px 0 0 80px">
          <el-tag
            v-for="tag in Capacitytags"
            :key="tag.name"
            style="margin: 0 10px 10px 0"
          >
            {{ tag.name }}
          </el-tag>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="usedDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="saveconfig">确定</el-button>
      </span>
    </el-dialog>
    <!-- 设置报价金额 -->
    <el-dialog
      title="设置报价金额"
      :visible.sync="editPriceShow"
      :close-on-click-modal="false"
      width="1550px"
      @closed="editPriceClosed"
    >
      <div style="margin-bottom: 20px">
        <div v-if="skuCopyData.quoteWay == '01'">
          <span style="margin-right: 100px"
            >未触发风险订单：<span style="color: #0981ff"
              >自动报价=对应机况报价</span
            ></span
          ><span
            >已触发风险订单：<span style="color: red"
              >自动报价=对应机况报价×高风险订单异常系数</span
            ></span
          >
        </div>
        <div v-if="skuCopyData.quoteWay == '00' && clickShow !== 'honor'">
          <span style="margin-right: 100px"
            >未触发风险订单：<span style="color: #0981ff"
              >自动报价=预估价×机况系数
            </span></span
          ><span
            >已触发风险订单：<span style="color: red"
              >自动报价=预估价×高风险订单异常系数</span
            ></span
          >
        </div>
        <div v-if="skuCopyData.quoteWay == '00' && clickShow === 'honor'">
          <span style="margin-right: 100px"
            >未触发风险订单：<span style="color: #0981ff"
              >自动报价=分毫靓机价×机况系数</span
            ></span
          ><span
            >已触发风险订单：<span style="color: red"
              >自动报价=分毫靓机价×高风险订单异常系数</span
            ></span
          >
        </div>
      </div>
      <div>
        <el-table
          border
          :data="shsDataList"
          v-if="skuCopyData.quoteWay == '00'"
        >
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column align="center" label="机型" prop="region">
            <template slot-scope="{ row }">
              <span>{{ row.modelName }}</span>
              <span v-if="row.skuType == 'target'"
                >-{{ row.machineCapacity }}</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="靓机系数" prop="priceNice">
            <template slot-scope="{ row ,$index}">
              <el-input
                @input="sbtextChange($event,$index,'priceNice')"
                v-model="row.priceNice"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="小花系数"
            prop="priceLittleSnow"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
              @input="sbtextChange($event,$index,'priceLittleSnow')"
                v-model="row.priceLittleSnow"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="大花系数" prop="priceBigSnow">
            <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceBigSnow')"
                v-model="row.priceBigSnow"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能正常系数"
            prop="priceOutBurstNormal"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceOutBurstNormal')"
                v-model="row.priceOutBurstNormal"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能异常系数"
            prop="priceOutBurstError"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceOutBurstError')"
                v-model="row.priceOutBurstError"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能正常系数"
            prop="priceInnerBurstNormal	"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceInnerBurstNormal')"
                v-model="row.priceInnerBurstNormal"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能异常系数"
            prop="priceInnerBurstError"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceInnerBurstError')"
                v-model="row.priceInnerBurstError"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(正常开机和无法标记机况)"
            prop="priceOtherCanStartingUp"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceOtherCanStartingUp')"
                v-model="row.priceOtherCanStartingUp"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(不能开机)"
            prop="priceOtherUnStartingUp"
          >
          <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceOtherUnStartingUp')"
                v-model="row.priceOtherUnStartingUp"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="高风险订单系数"
            prop="priceHighRisk"
          >
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="回收商拒绝报价、抢到后取消抢单或跳过则认为该订单为高风险订单;"
                placement="top"
              >
                <i class="el-icon-warning-outline" />
              </el-tooltip>
              高风险订单系数
            </template>
            <template slot-scope="{ row ,$index}">
              <el-input
               @input="sbtextChange($event,$index,'priceHighRisk')"
                v-model="row.priceHighRisk"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row, $index }">
              <el-button
                size="small"
                @click="deletSHSclick(row, $index)"
                type="danger"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-table
          border
          :data="shsDataList"
          v-if="skuCopyData.quoteWay == '01'"
        >
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column align="center" label="机型" prop="region">
            <template slot-scope="{ row }">
              <span>{{ row.modelName }}</span>
              <span v-if="row.skuType == 'target'"
                >-{{ row.machineCapacity }}</span
              >
            </template>
          </el-table-column>
          <el-table-column align="center" label="靓机价" prop="priceNice">
            <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceNice')"
                  v-model="row.priceNice"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="小花价" prop="priceLittleSnow">
            <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceLittleSnow')"
                  v-model="row.priceLittleSnow"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="大花价" prop="priceBigSnow">
            <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceBigSnow')"
                  v-model="row.priceBigSnow"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能正常价"
            prop="priceOutBurstNormal"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceOutBurstNormal')"
                  v-model="row.priceOutBurstNormal"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能异常价"
            prop="priceOutBurstError"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceOutBurstError')"
                  v-model="row.priceOutBurstError"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能正常价"
            prop="priceInnerBurstNormal"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceInnerBurstNormal')"
                  v-model="row.priceInnerBurstNormal"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能异常价"
            prop="priceInnerBurstError"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceInnerBurstError')"
                  v-model="row.priceInnerBurstError"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(正常开机和无法标记机况)"
            prop="priceOtherCanStartingUp"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceOtherCanStartingUp')"
                  v-model="row.priceOtherCanStartingUp"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(不能开机)"
            prop="priceOtherUnStartingUp"
          >
          <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceOtherUnStartingUp')"
                  v-model="row.priceOtherUnStartingUp"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="高风险订单"
            prop="priceHighRisk"
          >
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="回收商拒绝报价、抢到后取消抢单或跳过则认为该订单为高风险订单;"
                placement="top"
              >
                <i class="el-icon-warning-outline" />
              </el-tooltip>
              高风险订单
            </template>
            <template slot-scope="{ row ,$index}">
              <div class="dis_center">
                <el-input
                  maxlength="5"
                  @input="sbtePriceChange($event,$index,'priceHighRisk')"
                  v-model="row.priceHighRisk"
                  placeholder="请输入"
                ></el-input>
                元
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row, $index }">
              <el-button
                size="small"
                @click="deletSHSclick(row, $index)"
                type="danger"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import _api from "@/utils/request";
import moment from "moment";
var postionTime = Date.now();
export default {
  name: "failure",
  components: {},
  data() {
    return {
      isenActionShow: false,
      quedLOding: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= postionTime;
        },
      },
      brandList: [],
      modelList: [],
      capacityForm: {
        capacityan: [],
        capacityIosl: "",
        capacityIosr: [],
      },
      Capacitytags: [],
      tags: [],
      detalephoneId: "",
      dePhoneShow: false,
      modificationvisible: false,
      putBtnLoading: false,
      ruleForm: {
        ruleName: "",
        date: null,
        quoteWay: "",
        ruleExplain: "",
        isImmediate: null,
      },
      rules: {
        ruleName: [
          { required: true, message: "请输入规则名称", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
        quoteWay: [
          { required: true, message: "请选择报价方式", trigger: "change" },
        ],
      },
      insertionvisible: false,
      detalegroupdata: {},
      detaleShow: false,
      forbiddenShow: false,
      detalevisible: false,
      selectData: {},
      redyMerchantvisible: false,
      // 弹框里商家表头
      bargainMerchantColumnsDia: [
        {
          prop: "companyName",
          label: "商户名称",
        },
        {
          prop: "contact",
          label: "联系人",
        },
        {
          prop: "phone",
          label: "联系电话",
        },
      ],
      merchantListShow: false,
      newMerchantList: [],
      tableColumns2: [
        { label: "品牌", prop: "brandName" },
        { label: "机型", prop: "modelName" },
        { slotName: "sampleImage" },
        { slotName: "isMust" },
        { label: "机型来源", prop: "channel" },
        { slotName: "plople" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "isEnable" },
        { slotName: "operation" },
      ],
      tableLoading2: false,
      sampleImageSpecialData: [],
      personalConfigList: [],
      groupId: "",
      clickShow: "zhuanzhuan",
      ahsvisible: false,
      calssSelect: "01",
      usedDialogVisible: false,
      editPriceShow: false, // 弹框的出现与否
      faultTypeList: [],
      shsDataList: [],
      options: [],
      editPriceForm: {
        modelId: "",
        brandId: "",
        skuType: "",
      },
      editPricerules: {
        brandId: [
          { required: true, message: "请选择旧机品牌", trigger: "change" },
        ],
        modelId: [
          { required: true, message: "请选择旧机机型", trigger: "change" },
        ],
        skuType: [
          { required: true, message: "请选择旧机容量", trigger: "change" },
        ],
      },
      command: "",
      storeSelectList: [],
      merchantTableData: [],
      merchantParam: {
        companyId: "",
        contact: "",
        phone: "",
      },
      merchantTableLoading: false,
      rulerId: "",
      basicsConfigdata: {},
      basicscopydata: {},
      reshloading: false,
      topreshloading: false,
      uptype: "add",
      iosrightoptions: [],
      anhuoptions: [],
      iosleftoptions: [],
      trademarkname: "",
      skuresh: false,
      skumodeltype: "add",
      skuCopyData: {},
    };
  },
  watch: {
    calssSelect(val) {
      console.log(val);
    },
    "ruleForm.isImmediate"(v) {
      if (v) {
        postionTime = Date.now() - 8.64e7;
        if (this.ruleForm.date == null || this.ruleForm.date.length == 0) {
          this.ruleForm.date = [
            Number(moment().format("x")),
            Number(moment().format("x")),
          ];
        } else {
          this.ruleForm.date = [
            Number(moment().format("x")),
            this.ruleForm.date[1],
          ];
        }
      } else {
        postionTime = Date.now();
        if (this.ruleForm.date !== null || this.ruleForm.date.length !== 0) {
          this.ruleForm.date = [
            Number(moment().format("x")) + 24 * 3600 * 1000,
            this.ruleForm.date[1],
          ];
        }
      }
    },
  },
  created() {
    this.calssSelect = this.$route.query.calssSelect || "01";
    this.clickShow=this.$route.query.clickShow||'zhuanzhuan'
    this.getRuleList();
  },
  methods: {
    sbtextChange(e,index,propName){
      // console.log(e,index,propName,'当前值','当前索引','当前字段值');
      var len1 = e.substr(0, 1);
      var len2 = e.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (e.length > 1 && len1 == 0 && len2 != ".") {
        e = e.substr(1, 1);
      }
       //第一位不能是.
       if (len1 == ".") {
        e = "";
      }
      //限制只能输入一个小数点
      if (e.indexOf(".") != -1) {
        var str_ = e.substr(e.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          e = e.substr(0, e.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
       //保留两位小数，最大输入1.99
       e= e.replace(/^0[0-9]|^[2-9]|^1[0-9]|^2\.|[^\d.]/g,'').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
       this.shsDataList[index][propName]=e
     
    },
    sbtePriceChange(e,index,propName){
      // console.log(e,index,propName,'当前值','当前索引','当前字段值');
      var len1s = e.substr(0, 1);
      var len2s = e.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (e.length > 1 && len1s == 0 && len2s != ".") {
        e = e.substr(1, 1);
      }
       //第一位不能是.
       if (len1s == ".") {
        e = "";
      }
      e= e.replace(/[^0-9]/g, '')
      this.shsDataList[index][propName]=e
    },
    submitEditPrice() {
      _api
        .updateMachinePrice({
          id: this.skuCopyData.id,
          priceList: this.shsDataList,
        })
        .then((res) => {
          this.editPriceShow = false;
          this.$message.success("操作成功！");
          this.getMachineModel();
        });
    },
    deletSHSclick(data, index) {
      this.shsDataList.splice(index, 1);
    },
    Setquotation(row) {
      this.skuCopyData = row;
      _api.getMachineModelPrice({ id: row.id }).then((res) => {
        this.shsDataList = res.data;
        this.editPriceShow = true;
      });
    },
    isenActionSubmit() {
      _api
        .updateStateModel({
          id: this.skuCopyData.id,
          state: this.skuCopyData.isEnable ? "01" : "00",
        })
        .then((res) => {
          this.skuCopyData = {};
          this.$message.success("操作成功！");
          this.isenActionShow = false;
        });
    },
    switchChange(row) {
      this.skuCopyData = row;
      this.isenActionShow = true;
    },
    isenActionClosed() {
      this.isenActionShow = false;
      this.getMachineModel();
    },
    Modifyspecification(row) {
      this.skumodeltype = "action";
      this.skuCopyData = row;
      this.editPriceForm.modelId = row.modelId;
      this.editPriceForm.brandId = row.brandId;
      this.editPriceForm.skuType = row.skuType;
      if (row.skuType == "target") {
        this.tags = row.machineCapacity.split("、").map((item) => {
          return {
            name: item,
          };
        });
      } else {
        this.tags = [];
      }
      this.getBrandList();
      _api
        .listByTypeBrand({
          type: "00",
          brandId: this.editPriceForm.brandId,
          machineTypeId: 26,
        })
        .then((res) => {
          this.modelList = res.data;
        });
      this.ahsvisible = true;
    },
    // 获取旧机品牌列表
    getBrandList() {
      _api.oldBrandSelectByType({ typeId: 26 }).then((res) => {
        this.brandList = res.data || [];
      });
    },
    getModelidchange() {
      this.editPriceForm.skuType = "";
      this.tags = [];
    },
    // 获取机型
    getModelList() {
      this.modelList = [];
      this.editPriceForm.modelId = "";
      this.editPriceForm.skuType = "";
      this.tags = [];
      if (this.editPriceForm.brandId) {
        _api
          .listByTypeBrand({
            type: "00",
            brandId: this.editPriceForm.brandId,
            machineTypeId: 26,
          })
          .then((res) => {
            this.modelList = res.data;
          });
      }
    },
    BtnClick(type) {
      this.clickShow = type;
      this.getRuleList();
    },
    //规则列表
    getRuleList() {
      this.topreshloading = true;
      _api.getRuleList({ channelCode: this.clickShow }).then((res) => {
        this.personalConfigList = res.data;
        if (res.data.length > 0) {
          this.rulerId = res.data[0].id;
          this.groupId = res.data[0].id + "";
          this.getRuleInfo();
          this.getMachineModel();
        }
        this.topreshloading = false;
      });
    },
    //规则详情
    getRuleInfo() {
      this.reshloading = true;
      _api.getRuleInfo({ id: this.rulerId }).then((res) => {
        this.basicsConfigdata = JSON.parse(JSON.stringify(res.data));
        this.basicscopydata = JSON.parse(JSON.stringify(res.data));
        this.reshloading = false;
      });
    },
    //指定机型列表
    getMachineModel() {
      _api.getMachineModel({ ruleId: this.rulerId }).then((res) => {
        if (res.data.length > 0) {
          this.sampleImageSpecialData = res.data.map((item) => {
            return {
              isEnable: item.state == "01",
              ...item,
            };
          });
        } else {
          this.sampleImageSpecialData = [];
        }
      });
    },
    anzhCapacity(e) {
      console.log(e);
      this.Capacitytags = e.map((item) => {
        return {
          name: item,
        };
      });
    },
    iosRightCapacity(e) {
      console.log(e);
      this.Capacitytags = e.map((item) => {
        return {
          name: this.capacityForm.capacityIosl + "+" + item,
        };
      });
    },
    iosLeftCapacity(e) {
      console.log(e);
      this.Capacitytags = [];
    },
    oldPhonese(e) {
      if (this.skumodeltype == "add") {
        if (
          this.editPriceForm.skuType == "target" &&
          (!this.editPriceForm.modelId || !this.editPriceForm.brandId)
        ) {
          return this.$message.error("请先选择完整的旧机品牌和机型");
        } else if (
          this.editPriceForm.skuType == "target" &&
          this.editPriceForm.modelId &&
          this.editPriceForm.brandId
        ) {
          this.getMachineCapacityList();
        }
      }
    },
    //获取机型容量列表
    getMachineCapacityList() {
      this.skuresh = true;
      _api
        .getMachineCapacityList({ machineId: this.editPriceForm.modelId })
        .then((res) => {
          if (res.data.length > 0) {
            this.tags = res.data.map((item) => {
              return {
                name: item,
              };
            });
          }
        })
        .finally((all) => {
          this.skuresh = false;
        });
    },
    addCapacity() {
      this.capacityForm = {
        capacityan: [],
        capacityIosl: "",
        capacityIosr: [],
      };
      this.Capacitytags = [];
      _api
        .gettongMachineSpec({ brandId: this.editPriceForm.brandId })
        .then((res) => {
          if (res.data.randomAccessMemory !== null) {
            this.iosrightoptions = res.data.memory.filter(item=>item.specName!='无'); 
            this.iosleftoptions =res.data.randomAccessMemory.filter(item=>item.specName!='无');
          } else {
            this.anhuoptions = res.data.memory.filter(item=>item.specName!='无');
          }
        });
      this.usedDialogVisible = true;
    },
    capacityClose(index) {
      console.log(index);
      this.tags.splice(index, 1);
      this.$forceUpdate();
    },
    dePhnoeSubmit() {
      _api.removeMachineModelById({ id: this.detalephoneId }).then((res) => {
        this.$message.success("操作成功！");
        this.getMachineModel();
        this.dePhoneShow = false;
      });
    },
    modificationconfrim() {
      this.modificationvisible = false;
      this.updataRUlercontent();
    },
    updateRole() {
      this.uptype = "action";
      console.log(this.basicscopydata);
      this.ruleForm.ruleName = this.basicscopydata.ruleName;
      this.ruleForm.date = [
        moment(this.basicscopydata.effectStartTime).format("x"),
        moment(this.basicscopydata.effectEndTime).format("x"),
      ];
      this.ruleForm.quoteWay = this.basicscopydata.quoteWay;
      this.ruleForm.ruleExplain = this.basicscopydata.ruleExplain;
      if (this.basicscopydata.isImmediate) {
        this.ruleForm.isImmediate = this.basicscopydata.isImmediate;
      } else {
        this.ruleForm.isImmediate = "";
      }
      this.insertionvisible = true;
    },
    biddenRole() {
      this.detalegroupdata = this.basicsConfigdata;
      this.forbiddenShow = true;
    },
    detaleDialogClosed() {
      this.detalephoneId = "";
      this.command = "";
    },
    rolSureconfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          if (this.uptype == "add") {
            _api
              .addselefzhiPhoneRule({
                channelCode: this.clickShow,
                effectEndTime: this.ruleForm.date[1],
                effectStartTime: this.ruleForm.date[0],
                isImmediate: this.ruleForm.isImmediate,
                quoteWay: this.ruleForm.quoteWay,
                ruleExplain: this.ruleForm.ruleExplain,
                ruleName: this.ruleForm.ruleName,
              })
              .then((res) => {
                this.$message.success("添加成功！");
                this.insertionvisible = false;
                this.getRuleList();
              });
          } else {
            console.log(this.ruleForm.quoteWay, this.basicscopydata.quoteWay);
            if (this.ruleForm.quoteWay == this.basicscopydata.quoteWay) {
              this.updataRUlercontent();
            } else {
              this.modificationvisible = true;
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    updataRUlercontent() {
      _api
        .updatePhoneRule({
          id: this.basicscopydata.id,
          channelCode: this.clickShow,
          effectEndTime: this.ruleForm.date[1],
          effectStartTime: this.ruleForm.date[0],
          isImmediate: this.ruleForm.isImmediate,
          quoteWay: this.ruleForm.quoteWay,
          ruleExplain: this.ruleForm.ruleExplain,
          ruleName: this.ruleForm.ruleName,
        })
        .then((res) => {
          this.$message.success("修改成功！");
          this.insertionvisible = false;
          this.getRuleList();
        }).catch(()=>{
          this.quedLOding = false;
        });
    },
    closeRued() {
      this.ruleForm= {
        ruleName: "",
        date: null,
        quoteWay: "",
        ruleExplain: "",
        isImmediate: null,
      }
      this.$refs['ruleForm'].resetFields();
      this.insertionvisible = false;
    },
    // 删除弹框确定
    detaleDialogSubmit() {
      this.putBtnLoading = true;
      _api
        .removeRuleById({
          command: this.command,
          id: this.detalegroupdata.id,
        })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.putBtnLoading = false;
          this.detaleShow = false;
          this.getRuleList();
        })
        .catch(() => {
          this.putBtnLoading = false;
        });
    },
    // 禁用弹框确定
    forbidSubmit() {
      this.putBtnLoading = true;
      _api
        .stopRuleByIdMachineModel({
          command: this.command,
          id: this.detalegroupdata.id,
        })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.putBtnLoading = false;
          this.forbiddenShow = false;
          this.getRuleInfo();
        })
        .catch(() => {
          this.putBtnLoading = false;
        });
    },
    phoneDelate(row) {
      this.detalephoneId = row.id;
      this.dePhoneShow = true;
    },
    delline(row) {
      this.selectData = row;
      this.detalevisible = true;
    },
    seaRmRcant() {
      this.redyMerchantvisible = true;
    },
    handSelectMerchant(selection, row) {
      this.newMerchantList = selection;
    },
    merchantSave() {
      if (!this.newMerchantList.length) {
        return this.$message.error("请选择商户");
      }
      this.newMerchantList.forEach((item) => {
        this.onRuleInfo.companyIdList.push(item.companyId);
        this.ruleCompanyIds.push(item.companyId);
      });
      this.onRuleInfo.companyList.push(...this.newMerchantList);
      console.log(
        this.onRuleInfo.companyIdList,
        "this.onRuleInfo.companyIdList"
      );
      this.merchantListShow = false;
    },
    addMerchant() {
      this.newMerchantList = [];
      this.merchantListShow = true;
      this.getStoreSelectList();
      this.searchMerchantClick();
    },
    getStoreSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.storeSelectList = res.data;
        }
      });
    },
    searchMerchantClick() {
      this.merchantTableLoading = true;
      // 获取添加门店列表
      this.merchantParam.outType = this.bargainActiveName;
      _api
        .getGeneralList(this.merchantParam)
        .then((res) => {
          if (res.code === 1) {
            console.log(
              this.ruleCompanyIds,
              "ruleCompanyIdsruleCompanyIdsruleCompanyIds"
            );
            if (this.ruleCompanyIds && this.ruleCompanyIds.length) {
              res.data = res.data.filter(
                (item) => !this.ruleCompanyIds.includes(item.companyId)
              );
            }
            this.merchantTableData = res.data;
          }
        })
        .finally(() => {
          this.merchantTableLoading = false;
        });
    },
    manageModel() {},
    addSelfConfig() {
      this.uptype = "add";
      this.insertionvisible = true;
    },
    groupChange(e) {
      this.groupId = e.name;
      this.rulerId = e.name;
      this.getRuleInfo();
      this.getMachineModel();
    },
    removeTab(e) {
      console.log(e, this.personalConfigList);
      this.detalegroupdata = this.personalConfigList.filter(
        (item) => item.id == e
      )[0];
      console.log(this.detalegroupdata);
      this.detaleShow = true;
    },
    saveSHSclick() {
      this.skumodeltype = "add";
      this.getBrandList();
      this.ahsvisible = true;
    },
    resetSHSForm(formName) {
      this.editPriceForm= {
        modelId: "",
        brandId: "",
        skuType: "",
      }
      this.$refs[formName].resetFields();
      this.ahsvisible = false;
    },
    //保存SHS配置
    Shsconfrim() {
      _api.saveSHSConfig().then((res) => {
        this.$message({
          showClose: true,
          message: "保存成功！",
          type: "success",
        });
        this.shsDataList[this.shsIndex].isEitd = true;
        this.ahsvisible = false;
      });
    },
    offaddPhoneclose() {
      this.resetSHSForm("editPriceForm");
    },
    phoneModelSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.editPriceForm);
          if (this.skumodeltype == "add") {
            if (this.tags.length == 0 && this.editPriceForm.skuType !== "all") {
              return this.$message.error("请添加自定义旧机容量");
            }
            _api
              .addMachineModel({
                brandId: this.editPriceForm.brandId,
                machineCapacity: this.tags.map((item) => item.name),
                modelId: this.editPriceForm.modelId,
                ruleId: this.rulerId,
                skuType: this.editPriceForm.skuType,
              })
              .then((res) => {
                this.$message({
                  showClose: true,
                  message: "添加成功！",
                  type: "success",
                });
                this.ahsvisible = false;
                this.getMachineModel();
              });
          } else {
            _api
              .updateMachineCapacity({
                id: this.skuCopyData.id,
                machineCapacity: this.tags.map((item) => item.name),
                skuType: this.editPriceForm.skuType,
              })
              .then((res) => {
                this.$message({
                  showClose: true,
                  message: "修改成功！",
                  type: "success",
                });
                this.ahsvisible = false;
                this.getMachineModel();
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭编辑
    editPriceClosed() {
    },
    //关闭弹窗
    handleDialogClosed() {
      this.usedDialogVisible = false;
      this.command = "";
    },
    handleCurrentChange() {
      _api.getEstimatedConfig().then((res) => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
        }
      });
    },
    // 保存
    saveconfig() {
      if (this.Capacitytags.length == 0) {
        return this.$message.error("请选择完整容量！");
      }
      console.log(this.Capacitytags, this.tags);
      this.tags.push(...this.Capacitytags);
      //去重后加入
      const uniqueArr = this.tags.filter(
        (item, index) =>
          this.tags.findIndex((i) => i.name === item.name) === index
      );
      this.tags = uniqueArr;
      this.usedDialogVisible = false;
      console.log(this.tags);
    },
  },
};
</script>
    
<style scoped lang="scss" ref="stylesheet/scss">
.status_st {
  color: #67c23a;
  padding: 1px 5px;
  border: 1px solid #67c23a;
  border-radius: 15px 15px 15px 4px;
}
.status_grd {
  color: #909399;
  padding: 1px 5px;
  border: 1px solid #909399;
  border-radius: 15px 15px 15px 4px;
}
.blue_text {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
.action_center {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 12px;
  color: #666666;
  > div {
    margin-right: 50px;
  }
}
.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .faultBtn {
    width: 450px;
    height: 42px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 40px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 40px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}
.add_top_role {
  margin-bottom: 20px;
}
.class_type {
  padding: 0 20px 20px 0px;
  .tip {
    font-size: 14px;
    color: #666666;
  }

  /deep/ .el-radio-group {
    .el-radio-button {
      margin-right: 20px;

      .el-radio-button__inner {
        border-radius: 4px;
        border: 1px solid #0981ff;
      }
    }
  }
}
.tab_box {
  display: flex;
  .add-btn {
    margin-bottom: 14px;
    margin-left: 20px;
  }
  /deep/.el-tabs__new-tab {
    display: none !important;
  }
  /deep/.el-tabs__nav-wrap::after {
    display: none !important;
  }
}
.title-base {
  font-weight: 700;
  font-size: 15px;
  height: 18px;
  border-left: 6px solid #3399ff;
  padding: 0 10px;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  align-items: center;
}
.case {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  padding: 14px 0;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.dis_center {
  display: flex;
  align-items: center;
}
.self_allra {
  /deep/.el-checkbox__input {
    /deep/.el-checkbox__inner {
      border-radius: 50%;
    }
  }
}
</style>
    